<template>
  <div id="app">
    <div id="wrapper">
      <div class="stretchy-wrapper">
        <div class="image-box">
          <transition-group name="fade">
            <div v-for="number in [currentNumber]" :key="number" class="image"
            :style="'background-image: url(' + currentImage + ')'"></div>
          </transition-group>
        </div>
      </div>
      <div class="text-container">
        <div>
          <p>
            <img :src="require('@/assets/logo.png')" alt="Goldenhill" class="logo">
          </p>
          <p>
            Goldenhill SA is an international investment company active in the real estate sector.
            We manage our fully owned properties in Luxembourg, Germany, Portugal and Italy.
          </p>
        </div>
        <div>
          <p>
            Goldenhill SA<br>
            Via Matorèll 31a<br>
            CH-6926 Montagnola<br>
            Switzerland<br>
            T +41 91 993 38 20<br>
            info@goldenhill.group
          </p>
          <p>
            <!-- Office hours:<br> -->
            <!-- Due to the actual Covid-19 situation, our offices may not be fully operational at all times.<br> -->
            <!-- Visits to be arranged by appointment through info@goldenhill.group.<br> -->
            <!-- Monday, Wednesday, Thursday 8 a.m. to 2 p.m.<br>
            Tuesday and Friday 09.30 a.m. to 4 p.m.<br> -->
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      currentNumber: 0,
      timer: null,
      images: [
        //
      ],
      folder: 'w1100'
    }
  },
  mounted () {
    this.setImageSize()
    this.$nextTick(() => {
      this.startRotation()
    })
  },
   created () {
    window.addEventListener('resize', this.setImageSize)
  },
  destroyed () {
    window.removeEventListener('resize', this.setImageSize)
  },
  methods: {
    setImageSize() {
      const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
      // const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      switch (true) {
        case w < 500:
          this.folder = 'w500'
          break
        case w < 700:
          this.folder = 'w700'
          break
        default:
          this.folder = 'w1100'
      }
      this.images = [
        require('@/assets/img/' + this.folder + '/1.jpg'),
        require('@/assets/img/' + this.folder + '/2.jpg'),
        require('@/assets/img/' + this.folder + '/3.jpg'),
        require('@/assets/img/' + this.folder + '/4.jpg'),
        require('@/assets/img/' + this.folder + '/5.jpg')
      ]
    },
    startRotation () {
      this.timer = setInterval(this.next, 4000)
    },
    next () {
      this.currentNumber += 1
    }
  },
  computed: {
    currentImage () {
      return this.images[Math.abs(this.currentNumber) % this.images.length]
    }
  }
}
</script>

<style lang='scss'>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.8s ease;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
  position: absolute;
  left: 0;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  visibility: hidden;
}
.image-box {
  .image {
    height: 100%;
    width: 100%;
    background-size: cover;
  }
}
.logo {
  width: 100px;
}
.text-container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  div {
    display: flex;
    flex: 1;
  }
}
p {
  flex: 1;
  padding: 0 20px;
  &:last-child {
    flex: 2;
  }
}
body {
	width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}
#app  {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
#wrapper {
  width: 75%;
}
div.stretchy-wrapper {
	width: 100%;
	padding-bottom: 56.25%; /* 16:9 */
	position: relative;
}
div.stretchy-wrapper > div {
	position: absolute;
	top: 0; bottom: 0; left: 0; right: 0;
	color: white;
	font-size: 24px;
	text-align: center;
}
@media screen and (max-width: 1000px) {
  #app {
    margin-top: 0;
  }
  #wrapper {
    width: 100%;
  }
  .text-container {
    div {
      flex-direction: column;
    }
  }
}
@media screen and (max-width: 500px) {
  .text-container {
    flex-direction: column;
  }
}
</style>
